import React from "react";
import { Col, Row } from "react-bootstrap";

const TopCont = () => {
  return (
    <div className="topCont bg-light px-5">
      <Row>
        <Col className="left">
          <a href="" className="nav-item">
            Library
          </a>
          <a className="sepa mx-2">|</a>
          <a href="" className="nav-item">
            Privacy Notice
          </a>
        </Col>
        <Col className="ccol">
          <div className="right">
            <div className="loc me-3">
              <a href="">
                <i class="fa fa-map-marker fa-sm me-2" aria-hidden="true"></i>
                Dar Es Salaam
              </a>
            </div>
            <div className="tel">
              <i class="fa fa-phone fa-sm me-2" aria-hidden="true"></i>
              <a href="tel:+255717425183" target="_blank" className="nav-item">
                +255717425183
              </a>
              <a className="sepa mx-2">|</a>
              <a href="tel:+255717425183" target="_blank" className="nav-item">
                +255717425183
              </a>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default TopCont;
