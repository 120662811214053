import React from "react";

import img from "./assets/round.png";

function NewsCard() {
  return (
    <div className="cad row mb-1">
      <div className="col img">
        <img src={img} className="cardImg" />
      </div>
      <div className="col body">
        <p>Adding and improving our organization structure</p>
        <p className="date">Jul 24 12:15</p>
      </div>
    </div>
  );
}

export default NewsCard;
