import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Headerz from "./components/Headerz";
import Services from "./components/Services";
import Footer from "./components/Footer";
import NewsPrograms from "./components/NewsPrograms";

function App() {
  return (
    <div className="App container-fluid">
      <Headerz />
      <Services />
      <NewsPrograms />
      <Footer />
    </div>
  );
}

export default App;
