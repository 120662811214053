import React from "react";

import logo from "./assets/round.png";

const Footer = () => {
  return (
    <footer className="text-center footer mt-3">
      <div className="p-4">
        <section className="sosho mb-4">
          <a
            className="btn btn-outline-light btn-floating m-1"
            href="#!"
            role="button"
          >
            <i className="fab fa-facebook-f"></i>
          </a>

          <a
            className="btn btn-outline-light btn-floating m-1"
            href="#!"
            role="button"
          >
            <i className="fab fa-twitter"></i>
          </a>

          <a
            className="btn btn-outline-light btn-floating m-1"
            href="#!"
            role="button"
          >
            <i className="fab fa-instagram"></i>
          </a>

          <a
            className="btn btn-outline-light btn-floating m-1"
            href="#!"
            role="button"
          >
            <i className="fab fa-linkedin-in"></i>
          </a>
        </section>

        <section className="leta">
          <form action="">
            <div className="row d-flex justify-content-center">
              <div className="col-auto">
                <p className="pt-2">
                  <strong>Sign up for our newsletter</strong>
                </p>
              </div>

              <div className="col-md-5 col-12">
                <div className="form-outline form-white mb-4">
                  <input
                    type="email"
                    id="form5Example21"
                    className="form-control"
                    placeholder="Email address"
                  />
                </div>
              </div>

              <div className="col-auto">
                <button type="submit" className="btn btn-outline-light mb-4">
                  Subscribe
                </button>
              </div>
            </div>
          </form>
        </section>

        <section className="mb-4 aot container">
          <p>
            TPPA is a registered entity that was formed to support and advance
            the privacy professional in the country. TPPA aims to serve as a
            platform for nurturing, supporting, influencing, and growing privacy
            expertise in the country. Whether you are an experienced expert or
            just starting out in the field of privacy, TPPA is the perfect place
            for you.
          </p>
        </section>

        <section className="">
          <div className="row">
            <div className="col-lg-3 col-md-6 mb-4 mb-md-0">
              <h5 className="text-uppercase">Logo</h5>

              <div className="logoC">
                <img
                  src={logo}
                  className="logo"
                  style={{ width: 100, height: 100 }}
                />
              </div>
            </div>

            <div className="col-lg-3 col-md-6 mb-4 mb-md-0">
              <h5 className="text-uppercase">Services</h5>

              <ul className="list-unstyled mb-0">
                <li>
                  <a href="#!" className="text-white">
                    Security
                  </a>
                </li>
                <li>
                  <a href="#!" className="text-white">
                    Confidentiality
                  </a>
                </li>
                <li>
                  <a href="#!" className="text-white">
                    Integrity
                  </a>
                </li>
                <li>
                  <a href="#!" className="text-white">
                    Availability
                  </a>
                </li>
              </ul>
            </div>

            <div className="col-md-4 col-lg-3 fcont col-xl-3 mx-auto mb-md-0 mb-4">
              <h5 className="text-uppercase">Contact</h5>
              <p>
                <i class="fas fa-home me-3"></i> Dar Es Salaam, TZ
              </p>
              <p>
                <a
                  href="mailto: info@tppa.co.tz?subject =Your%20Subject%20Here"
                  target="_blank"
                >
                  <i class="fas fa-envelope me-3"></i>
                  info@tppa.co.tz
                </a>
              </p>
              <p>
              <a
                  href="tel:+255717425183"
                  target="_blank"
                >
                  <i class="fas fa-phone me-3"></i>Call Us +255717425183
                </a>
                
              </p>
              <p>
              <a
                  href="tel:+255717425183"
                  target="_blank"
                >
                <i class="fas fa-print me-3"></i> +255717425183
                </a>
              </p>
            </div>

            <div class="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
              <h6 class="text-uppercase fw-bold mb-4">Useful links</h6>
              <p>
                <a
                  class="text-reset"
                  href="https://server14.tanzaniaservers.com/roundcube/?task=mail"
                  target="_blank"
                >
                  Members Only
                </a>
              </p>
            </div>
          </div>
        </section>
      </div>

      <div className="text-center copy p-3">
        © 2023 Copyright -
        <a
          className="text-white ps-1 copi"
          href="https://www.afrodemoz.com"
          target="_blank"
        >
          AfroDemoz Tech
        </a>
      </div>
    </footer>
  );
};

export default Footer;
