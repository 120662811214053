import React from "react";
import NePro from "./NePro";

function NewsPrograms() {
  return (
    <div className="container newsPrograms section bg-light rounded mt-3" id="#nePro">
      <div className="topo">
        <h1 className="title2 pt-5">Events & Programs</h1>
        <div className="botoLine m-auto"></div>
      </div>
      <NePro/>
    </div>
  );
}

export default NewsPrograms;
