import React from "react";
import { Col, Row } from "react-bootstrap";
import NewsCard from "./NewsCard";
import Tweets from "./Tweets";
import { TwitterTimelineEmbed } from "react-twitter-embed";

function NePro() {
  return (
    <Row className="m-3 nePro">
      <Col className="left">
        <Row className="m-0 p-0 mx-1">
          <Col className="col left">
            <div className="topo">
              <h4>Programs</h4>
            </div>
            <ul>
              <li>
                <a href="/">Mahakama</a>
              </li>
              <li>
                <a href="/">Mahakama</a>
              </li>
              <li>
                <a href="/">Mahakama</a>
              </li>
            </ul>
          </Col>
          <Col className="right">
            <div className="topo">
              <h4>News</h4>
            </div>
            <a className="cadLink" href="/">
              <NewsCard />
            </a>
            <a className="cadLink" href="/">
              <NewsCard />
            </a>
          </Col>
        </Row>
      </Col>
      <Col className="right">
        <div className="topo">
          <a href="/">
            <h4>@TPpA Tweets</h4>
          </a>
          <Tweets/>
        </div>
      </Col>
    </Row>
  );
}

export default NePro;
