import React from "react";
import WeDo from "./WeDo";

const Services = () => {
  return (
    <div className="container services section bg-light rounded" id="#services">
      <div className="topo">
        <h1 className="title2 pt-5">What We Do!</h1>
        <div className="botoLine m-auto"></div>
      </div>
      <WeDo />
    </div>
  );
};

export default Services;
