import React from "react";
import { Nav, NavDropdown, Navbar } from "react-bootstrap";

import logo from "./assets/long.png";

const NavBar = () => {
  return (
    <Navbar
      collapseOnSelect
      sticky="top"
      expand="lg"
      className="nova bg-body-tertiary px-5"
    >
      <>
        <Navbar.Brand href="/">
          <img
            className="d-inline-block align-text-top"
            style={{ width: 150, height: 30 }}
            src={logo}
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="navbarScroll">
          <Nav className="ms-auto" navbarScroll>
            <Nav.Link href="/">Home</Nav.Link>
            <NavDropdown title="About" id="collasible-nav-dropdown">
              <NavDropdown.Item href="#action/3.2">About Us</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Contact Us</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.4">LeaderShip</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.4">MemberShip</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.4">
                Privacy Notice
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link href="#services">Services</Nav.Link>
            <Nav.Link href="#">Resources</Nav.Link>
            <Nav.Link href="#pricing">News & Programs</Nav.Link>
            <Nav.Link href="#pricing">Opportunities</Nav.Link>
            <Nav.Link href="#pricing">Departments</Nav.Link>
            <Nav.Link className="nono" href="#pricing">
              Library
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </>
    </Navbar>
  );
};

export default NavBar;
