import React from "react";
import { Col, Row } from "react-bootstrap";

const WeDo = () => {
  return (
    <Row className="m-3 weDo">
      <Col className="card">
        <div className="icon">
          <div className="ic">
            <i class="fa fa-cloud fa-4x" aria-hidden="true"></i>
          </div>
        </div>
        <div className="title2 mt-3">
          <h5>Confidentiality</h5>
        </div>
        <div className="body">
          <p>
            Guardians of Confidentiality Empowering Data Privacy Advocates
            Together, We Build Trust in a Digital!
          </p>
        </div>
        <a href="" className="btn text-dark readBtn">
          Read More
        </a>
      </Col>
      <Col className="card">
        <div className="icon">
          <div className="ic">
            <i class="fa fa-lock fa-4x" aria-hidden="true"></i>
          </div>
        </div>
        <div className="title2 mt-3">
          <h5>Integrity</h5>
        </div>
        <div className="body">
          <p>
            Guardians of Confidentiality Empowering Data Privacy Advocates
            Together, We Build Trust in a Digital!
          </p>
        </div>
        <a href="" className="btn text-dark readBtn">
          Read More
        </a>
      </Col>
      <Col className="card">
        <div className="icon">
          <div className="ic">
            <i class="fa fa-laptop fa-3x" aria-hidden="true"></i>
          </div>
        </div>
        <div className="title2 mt-3">
          <h5>Availability</h5>
        </div>
        <div className="body">
          <p>
            Guardians of Confidentiality Empowering Data Privacy Advocates
            Together, We Build Trust in a Digital!
          </p>
        </div>
        <a href="" className="btn text-dark readBtn">
          Read More
        </a>
      </Col>
    </Row>
  );
};

export default WeDo;
