import React from "react";
import NavBar from "./NavBar";
import TopCont from "./TopCont";

const Headerz = () => {
  return (
    <header className="header">
      <div className="topoLine"></div>
      <TopCont />
      <NavBar />
      <div className="boto">
        <div className="cent mx-1">
          <h1 className="botoHead text-light title">
            Tanzania Privacy Professionals Association
          </h1>
          <div className="botoBody">
            <p>
              Guardians of Confidentiality: Empowering Data Privacy Advocates
              Together, We Build Trust in a Digital Era!
            </p>
            <a className="btn readBtn">Find Out More</a>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Headerz;
